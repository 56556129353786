(function (_) {
    /**
     * Get a list of messages
     * @param (*} query
     */
    GApi.prototype.listMessages = function (query, callbacks, context) {
        this.request({
            url: '/message/list',
            query: query,
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Get information about a single message
     * @param {String} reference the message's reference
     * @param {Boolean} [countView] whether to count a view for the message or not (defaults to false)
     */
    GApi.prototype.getMessage = function (reference, countView, callbacks, context) {
        var query = {};
        if (countView) {
            query.countView = '';
        }

        this.request({
            url: '/message/' + encodeURIComponent(reference),
            query: query,
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Create a message
     * @param (*} data
     */
    GApi.prototype.createMessage = function (data, callbacks, context) {
        var callbacks = callbacks || {};

        if (!data || !data.message) {
            if (callbacks.fail) {
                callbacks.fail(400, '', ['No message given.']);
            }
            return;
        }

        this
            .request({
                method: 'POST',
                url: '/message',
                auth: true,
                dataType: 'json',
                data: data
            }, {
                done: function (message) {
                    this.createMessageComment(message.reference, {
                        message: data.message
                    }, {
                        done: function (comment) {
                            message.comments = message.comments + 1;
                            if (callbacks.done) {
                                callbacks.done(message);
                            }
                        },
                        fail: callbacks.fail,
                        always: callbacks.always
                    }, context);
                }.bind(this),
                fail: callbacks.fail
            }, context);
    };

    /**
     * Get info whether user liked a given message or not
     * @param (String} reference the message to check for
     */
    GApi.prototype.isMessageLiked = function (reference, callbacks, context) {
        this.request({
            url: '/like',
            query: {
                message: reference
            },
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Toggle the like status for the current user and a given message
     * @param (String} reference the message to toggle like for
     */
    GApi.prototype.toggleMessageLike = function (reference, callbacks, context) {
        this.request({
            method: 'POST',
            url: '/like',
            query: {
                message: reference
            },
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Get a list of comments for a message
     * @param (String} reference the message to check for
     */
    GApi.prototype.listMessageComments = function (reference, query, callbacks, context) {
        this.request({
            url: '/comment/list?message=' + encodeURIComponent(reference),
            query: query,
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Create a comment (reply) on a given message
     * @param (String} reference the message to create a reply for
     * @param {*} data
     */
    GApi.prototype.createMessageComment = function (reference, data, callbacks, context) {
        this.request({
            method: 'POST',
            url: '/comment?message=' + encodeURIComponent(reference),
            auth: true,
            data: data,
            dataType: 'json',
            responseType: 'json'
        }, callbacks, context);
    };
})(this);
