(function (_) {
    /**
     * Make a query request to get a list of fonts for certain parameters
     */
    GApi.prototype.listFonts = function (family, style, weight, category, skip, limit, callbacks, context) {
        this.request({
            method: 'GET',
            url: '/font/list',
            auth: true,
            query: {
                family: family,
                style: style,
                weight: weight,
                category: category,
                skip: skip,
                limit: limit
            }
        }, callbacks, context);
    };

    /**
     * Load a font and returns it as an arraybuffer
     * @param {String} family the font family
     * @param {{style: String, weight: Number, substitute: Boolean}} [map] optional settings
     * on the font to map, by defaults maps non-italic, regular weighted fonts and their substitutes
     */
    GApi.prototype.loadFont = function (family, map, callbacks, context) {
        this._getFont(family, map, 'url', {
            done: function (fontUrl) {
                this.request({url: fontUrl, responseType: 'arraybuffer'}, callbacks);
            }.bind(this),
            fail: callbacks.fail
        }, context);
    };

    /**
     * @param family
     * @param map
     * @param callbacks
     * @returns {*}
     * @private
     */
    GApi.prototype._getFont = function (family, map, return_, callbacks, context) {
        map = map || {};

        return this.request({
            method: 'GET',
            url: '/font/match/' + encodeURIComponent(family),
            query: {
                style: map.style || 'N',
                weight: map.weight || 400,
                substitute: map.hasOwnProperty('substitute') ? map.substitute : true,
                'return': return_
            }
        }, callbacks, context);
    };
})(this);
