(function (_) {
    /**
     * Get a list of available assets
     * @param {*} query
     * @return Promise
     */
    GApi.prototype.listAssets = function (query, callbacks, context) {
        this.request({
            method: 'GET',
            url: '/asset/list',
            query: query,
            auth: true
        }, callbacks, context);
    };

    /**
     * Get properties for a given asset
     * @param {String} reference the assets's reference
     * @param {Boolean} [countView] whether to count a view for the reference or not (defaults to false)
     */
    GApi.prototype.getAsset = function (reference, countView, callbacks, context) {
        var query = {};
        if (countView) {
            query.countView = '';
        }

        this.request({
            url: '/asset/' + encodeURIComponent(reference),
            query: query,
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };
})(this);