(function (_) {
    var IMAGE_MIME_TYPES = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml'];

    /**
     * List documents
     * @param {*} query the query for listing documents
     */
    GApi.prototype.listDocuments = function (query, callbacks, context) {
        this.request({
            url: '/document/list',
            query: query,
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Get properties for a given document
     * @param {String} reference the document's reference
     * @param {Boolean} [countView] whether to count a view for the document or not (defaults to false)
     */
    GApi.prototype.getDocument = function (reference, countView, callbacks, context) {
        var query = {};
        if (countView) {
            query.countView = '';
        }

        this.request({
            url: '/document/' + encodeURIComponent(reference),
            query: query,
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Creates a new document
     * @param {*} data document data
     */
    GApi.prototype.createDocument = function (data, callbacks, context) {
        this.request({
            method: 'POST',
            url: '/document',
            auth: true,
            dataType: 'json',
            data: data
        }, callbacks, context);
    };

    /**
     * Updates a document
     * @param {String} reference the document's reference
     * @param {*} data the new data to update the document with
     */
    GApi.prototype.updateDocument = function (reference, data, callbacks, context) {
        this.request({
            method: 'PUT',
            url: '/document/' + encodeURIComponent(reference),
            auth: true,
            dataType: 'json',
            data: data,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Duplicate a document
     * @param {String} reference the document's reference
     */
    GApi.prototype.duplicateDocument = function (reference, callbacks, context) {
        this.request({
            method: 'POST',
            url: '/document/' + encodeURIComponent(reference),
            auth: true
        }, callbacks, context);
    };

    /**
     * Delete a document
     * @param {String} reference the document's reference
     */
    GApi.prototype.deleteDocument = function (reference, callbacks, context) {
        this.request({
            method: 'DELETE',
            url: '/document/' + encodeURIComponent(reference),
            auth: true
        }, callbacks, context);
    };

    /**
     * List all collaborators for one or more documents
     * @param {String|Array<String>} reference one or more documents to get collaborators for
     * @param {Boolean} [includeParent] if set, includes inherited shares from parent(s), defaults to false
     */
    GApi.prototype.listDocumentCollaborators = function (reference, includeParent, callbacks, context) {
        var query = {};
        if (includeParent) {
            query.parent = '';
        }

        var references = typeof reference === 'string' ? [reference] : reference;

        this.request({
            method: 'POST',
            url: '/document/collaborators/list',
            query: query,
            auth: true,
            data: references,
            dataType: 'json',
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Put a new list of collaborators for one or more doucments
     * @param {String|Array<String>} reference one or more documents to set collaborators for
     * @param {Array<*>} collaborators the new collaborators
     */
    GApi.prototype.updateDocumentCollaborators = function (reference, collaborators, callbacks, context) {
        this.request({
            method: 'PUT',
            url: '/document/collaborators/update',
            auth: true,
            data: {
                references: typeof reference === 'string' ? [reference] : reference,
                collaborators: collaborators
            },
            dataType: 'json',
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Get info whether user liked a given document or not
     * @param (String} reference the document to check for
     */
    GApi.prototype.isDocumentLiked = function (reference, callbacks, context) {
        this.request({
            url: '/like',
            query: {
                document: reference
            },
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Toggle the like status for the current user and a given document
     * @param (String} reference the document to toggle like for
     */
    GApi.prototype.toggleDocumentLike = function (reference, callbacks, context) {
        this.request({
            method: 'POST',
            url: '/like',
            query: {
                document: reference
            },
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Get a list of comments for a document
     * @param (String} reference the document to check for
     */
    GApi.prototype.listDocumentComments = function (reference, query, callbacks, context) {
        this.request({
            url: '/comment/list?document=' + encodeURIComponent(reference),
            query: query,
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Create a comment on a given document
     * @param (String} reference the document to create a comment for
     * @param {*} data
     */
    GApi.prototype.createDocumentComment = function (reference, data, callbacks, context) {
        this.request({
            method: 'POST',
            url: '/comment?document=' + encodeURIComponent(reference),
            auth: true,
            data: data,
            dataType: 'json',
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Resolve the path for a given document
     * @param (String} reference the document reference for resolving the path to
     */
    GApi.prototype.resolveDocumentPath = function (reference, callbacks, context) {
        this.request({
            url: '/document/' + encodeURIComponent(reference) + '/resolve_path',
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Creates a document from a file into the currently active folder
     * @param {File|Blob} file the file or blob to create from, requires
     * a valid name and type and size
     * @param {Boolean} force if true enforces the creation, otherwise if false
     * this will check and return if an identical document exists already.
     * @param callbacks
     */
    GApi.prototype.createDocumentFromFile = function (file, force, callbacks, context) {
        callbacks = callbacks || {};

        if (!this._activeFolder && !this._user) {
            if (callbacks.fail) {
                return callbacks.fail(400, 'no_folder_or_user');
            }
        }

        var type = null;
        if (IMAGE_MIME_TYPES.indexOf(file.type.toLowerCase()) >= 0) {
            type = 'image';
        } else {
            throw new Error('Invalid/unknown file type - ' + file.type);
        }

        var _createDocument = function () {
            var reader = new FileReader();
            reader.onload = function (readerEvent) {
                var fileBuffer = readerEvent.target.result;

                this.
                    createDocument({
                        name: file.name,
                        mimetype: file.type,
                        folder: this._activeFolder,
                        owner: this._user ? this._user.id : null
                    }, {
                        done: function (doc) {
                            switch (type) {
                                case 'image':
                                    this.
                                        saveImage(doc.reference, fileBuffer, file.type, {
                                            done: function () {
                                                if (callbacks.done) {
                                                    callbacks.done(doc);
                                                }
                                            },
                                            fail: callbacks.fail,
                                            progress: callbacks.progress,
                                            preview: callbacks.preview
                                        });
                                    break;
                            }
                        }.bind(this),
                        fail: callbacks.fail
                    }, context);
            }.bind(this);

            reader.readAsArrayBuffer(file);
        }.bind(this);

        if (force) {
            _createDocument();
        } else {
            var query = {
                name: file.name,
                mimetype: file.type,
                size: file.size,
                folder: this._activeFolder,
                owner: this._user ? this._user.id : null
            };

            this
                .request({url: '/document/exists', auth: true, query: query}, {
                    done: function (doc) {
                        if (doc) {
                            // an exact identical file already exists so return it and be done with it
                            if (callbacks.done) {
                                callbacks.done(doc);
                            }
                        } else {
                            // create a new document for the file and upload our contents
                            _createDocument();
                        }
                    }.bind(this),
                    fail: callbacks.fail
                }, context);
        }
    };

    /**
     * Load the content of a document. The result will depend on the type,
     * like a design will be delievered as parsed JSON and binary files like
     * images are returned as arraybuffers
     * @param {String|{*}} referenceOrDoc the document's reference or the document-data
     */
    GApi.prototype.loadDocument = function (referenceOrDoc, callbacks, context) {
        callbacks = callbacks || {};

        var _loadDoc = function (documentInfo) {
            var responseType = null;

            switch (documentInfo.category) {
                case 'design':
                    responseType = 'json';
                    break;

                case 'image':
                case 'font':
                    responseType = 'arraybuffer';
                    break;

                default:
                    throw new Error('Unknown document type.');
            }

            this
                .request({url: documentInfo.url, responseType: responseType, contentSize: documentInfo.size}, {
                    done: function (documentData) {
                        if (callbacks.done) {
                            callbacks.done(documentData, documentInfo);
                        }
                    },
                    fail: callbacks.fail,
                    always: callbacks.always,
                    progress: callbacks.progress
                }, context);
        }.bind(this);

        if (typeof referenceOrDoc === 'string') {
            this
                .getDocument(referenceOrDoc, false, {
                    done: _loadDoc,
                    fail: callbacks.fail
                }, context);
        } else {
            _loadDoc(referenceOrDoc);
        }
    };

    /**
     * Save a scene into an existing design document. This will also automatically
     * generate a preview image and upload it.
     * @param {String} reference the document's reference
     * @param {GScene} scene the scene to be saved into the design
     * @param {*} usedDocuments list of used documents
     */
    GApi.prototype.saveDesign = function (reference, scene, usedDocuments, callbacks, context) {
        callbacks = callbacks || {};

        var output = GNode.serialize(scene, {save: true});

        var bitmapWidth = null;
        var bitmapHeight = null;

        var paintBBox = scene.getPaintBBox();

        if (paintBBox.getWidth() > GApi.THUMBNAIL_SIZE || paintBBox.getHeight() > GApi.THUMBNAIL_SIZE) {
            bitmapWidth = bitmapHeight = new GLength(GApi.THUMBNAIL_SIZE);
        }

        var bitmap = scene.toBitmap(bitmapWidth, bitmapHeight, 0);
        bitmap.toImageBuffer(GBitmap.ImageType.PNG, function (previewBuffer) {
            var content = {
                type: 'application/gravit+design',
                width: Math.abs(scene.getProperty('w') || paintBBox.getWidth()),
                height: Math.abs(scene.getProperty('h') || paintBBox.getHeight()),
                data: output,
                compress: true
            };

            var preview = {
                width: bitmap.getWidth(),
                height: bitmap.getHeight(),
                type: 'image/png',
                target: 'preview',
                data: previewBuffer
            };

            this
                ._uploadDocumentContent(reference, content, {
                    progress: callbacks.progress,
                    fail: callbacks.fail,
                    done: function () {
                        this.
                            updateDocument(reference, {usedDocuments: usedDocuments}, {
                                done: function () {
                                    this
                                        ._uploadDocumentContent(reference, preview, {
                                            fail: callbacks.fail,
                                            done: callbacks.done
                                        });
                                }.bind(this),
                                fail: callbacks.fail
                            }, context);
                    }.bind(this)
                }, context);
        }.bind(this));
    };

    /**
     * Save an image into an existing document. This will also automatically
     * generate a preview image and upload it.
     * @param {String} refeference the document's reference
     * @param {Image|HTMLImageElement|ArrayBuffer|Blob} buffer the image's buffer containing the image
     * @param {String} type the image's mime-type which is either one of image/png,
     * image/jpeg, image/gif or image/svg+xml
     */
    GApi.prototype.saveImage = function (reference, image, type, callbacks, context) {
        callbacks = callbacks || {};

        this.imageThumbnail(image, GApi.THUMBNAIL_SIZE, function (previewDataUrl, previewWidth, previewHeight, sourceImage) {
            if (callbacks.preview) {
                callbacks.preview(previewDataUrl, previewWidth, previewHeight, sourceImage);
            }

            var content = {
                type: type,
                width: sourceImage.naturalWidth,
                height: sourceImage.naturalHeight,
                data: image,
                compress: true
            };

            var preview = {
                width: previewWidth,
                height: previewHeight,
                type: 'image/png',
                target: 'preview',
                data: this.dataUrlToBlob(previewDataUrl)
            };

            this
                ._uploadDocumentContent(reference, content, {
                    progress: callbacks.progress,
                    fail: callbacks.fail,
                    done: function () {
                        this
                            ._uploadDocumentContent(reference, preview, {
                                fail: callbacks.fail,
                                done: callbacks.done
                            }, context);
                    }.bind(this)
                }, context);
        }.bind(this));
    };

    /**
     * @param reference
     * @param content
     * @param callbacks
     * @private
     */
    GApi.prototype._uploadDocumentContent = function (reference, content, callbacks, context) {
        callbacks = callbacks || {};

        var target = content.target || 'content';
        var compress = content.compress || false;

        var query = {
            no_redirect: 'yes'
        };

        if (!isNaN(content.width) && !isNaN(content.height)) {
            query.width = content.width;
            query.height = content.height;
        }

        var uploadData = content.data;

        var size = content.size || 0;
        if (!size) {
            if (typeof uploadData === 'string') {
                size = uploadData.length;
            } else if (uploadData instanceof ArrayBuffer) {
                size = uploadData.byteLength;
            } else if (uploadData instanceof File) {
                size = uploadData.size;
            } else if (uploadData instanceof Blob) {
                size = uploadData.size;
            } else {
                throw new Error('no_size_available');
            }
        }

        query.size = size;

        this.
            request({
                method: 'PUT',
                url: '/document/' + encodeURIComponent(reference) + '/' + target,
                auth: true,
                query: query
            }, {
                done: function (uploadUrl) {
                    this._uploadCounter++;

                    var headers = {};

                    if (compress && (uploadData instanceof ArrayBuffer || typeof uploadData === 'string')) {
                        uploadData = pako.gzip(uploadData, {level: 9}).buffer;
                        headers['Content-Encoding'] = 'gzip';
                    }

                    this.
                        request({
                            method: 'PUT',
                            headers: headers,
                            url: uploadUrl,
                            dataType: content.type,
                            data: uploadData
                        }, {
                            done: callbacks.done,
                            fail: callbacks.fail,
                            progress: callbacks.progress,
                            always: function () {
                                this._uploadCounter--;
                                if (callbacks.always) {
                                    callbacks.always();
                                }
                            }.bind(this)
                        }, context);
                }.bind(this),
                fail: callbacks.fail
            }, context);
    };
})(this);
