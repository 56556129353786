(function (_) {
    /**
     * Create a new organization on the behalf of the current user
     * @param {*} data the new organization data
     */
    GApi.prototype.createOrganization = function (data, callbacks, context) {
        callbacks = callbacks || {};

        this.request({
            method: 'POST',
            url: '/org',
            auth: true,
            dataType: 'json',
            data: data
        }, {
            done: function (org) {
                this.loadUser();
                if (callbacks.done) {
                    callbacks.done(org);
                }
            }.bind(this),
            fail: callbacks.fail,
            always: callbacks.always,
            progress: callbacks.progress
        }, context);
    };

    /**
     * Get organization profile
     * @param {String} org the organization's name or ID
     */
    GApi.prototype.getOrganizationProfile = function (org, callbacks, context) {
        this.request({
            url: '/org/' + encodeURIComponent(org) + '/profile',
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Update an organization's profile
     * @param {String} org the organization's name or ID
     * @param {*} data the profile data
     */
    GApi.prototype.updateOrganizationProfile = function (org, data, callbacks, context) {
        this.request({
            method: 'PUT',
            url: '/org/' + encodeURIComponent(org) + '/profile',
            auth: true,
            dataType: 'json',
            data: data,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Update an organization's avatar
     * @param {String} org the organization's name or ID
     * @param {Blob} avatarBlob the avatar's binary blob (png image)
     */
    GApi.prototype.updateOrganizationAvatar = function (org, avatarBlob, callbacks, context) {
        var updateFunc = function (avatar, callbacks, context) {
            this.updateOrganizationProfile(org, {avatar: avatar}, callbacks, context);
        }.bind(this);

        this._updateAccountAvatar('/org/' + encodeURIComponent(org) + '/avatar', avatarBlob, updateFunc, callbacks, context);
    };

    /**
     * Get a list of organization teams
     * @param {String} org the organization's name or ID
     * @param (*} query
     */
    GApi.prototype.listOrganizationTeams = function (org, query, callbacks, context) {
        this.request({
            url: '/org/' + encodeURIComponent(org) + '/team/list',
            query: query,
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Get information about an organization team
     * @param {String} org the organization's name or ID
     * @param (String} team the team's name or ID
     */
    GApi.prototype.getOrganizationTeam = function (org, team, callbacks, context) {
        this.request({
            url: '/org/' + encodeURIComponent(org) + '/team/' + encodeURIComponent(team),
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Create a new team within an organization
     * @param {String} org the organization's name or ID
     * @param {*} data the new team data
     */
    GApi.prototype.createOrganizationTeam = function (org, data, callbacks, context) {
        this.request({
            method: 'POST',
            url: '/org/' + encodeURIComponent(org) + '/team',
            auth: true,
            dataType: 'json',
            data: data
        }, callbacks, context);
    };

    /**
     * Update team settings
     * @param {String} org the organization's name or ID
     * @param {String} team the team's name or ID
     * @param {*} data the new team data
     */
    GApi.prototype.updateOrganizationTeam = function (org, team, data, callbacks, context) {
        this.request({
            method: 'PUT',
            url: '/org/' + encodeURIComponent(org) + '/team/' + encodeURIComponent(team),
            auth: true,
            dataType: 'json',
            data: data,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Delete a team from an orgnaization
     * @param {String} org the organization's name or ID
     * @param {String} team the team's name or ID
     */
    GApi.prototype.deleteOrganizationTeam = function (org, team, callbacks, context) {
        this.request({
            method: 'DELETE',
            url: '/org/' + encodeURIComponent(org) + '/team/' + encodeURIComponent(team),
            auth: true
        }, callbacks, context);
    };

    /**
     * Get a list of organization members
     * @param {String} org the organization's name or ID
     * @param (*} query
     */
    GApi.prototype.listOrganizationMembers = function (org, query, callbacks, context) {
        this.request({
            url: '/org/' + encodeURIComponent(org) + '/member/list',
            query: query,
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Add a member to an organization
     * @param {String} org the organization's name or ID
     * @param {String} user the user's name or ID to be added
     */
    GApi.prototype.addMemberToOrganization = function (org, user, callbacks, context) {
        this.request({
            method: 'POST',
            url: '/org/' + encodeURIComponent(org) + '/member',
            auth: true,
            dataType: 'json',
            data: {
                user: user
            }
        }, callbacks, context);
    };

    /**
     * Update membership of an user and organization
     * @param {String} org the organization's name or ID
     * @param {String} user the user's name or ID
     * @param {*} data the membership data
     */
    GApi.prototype.updateOrganizationMember = function (org, user, data, callbacks, context) {
        callbacks = callbacks || {};

        this.request({
            method: 'PUT',
            url: '/org/' + encodeURIComponent(org) + '/member/' + encodeURIComponent(user),
            auth: true,
            dataType: 'json',
            data: data,
            responseType: 'json'
        }, {
            done: function () {
                if (this._user && this._user.id === user) {
                    this.loadUser();
                }
                if (callbacks.done) {
                    callbacks.done();
                }
            }.bind(this),
            fail: callbacks.fail,
            always: callbacks.always,
            progress: callbacks.progress
        }, context);
    };

    /**
     * Remove a member from an organization
     * @param {String} org the organization's name or ID
     * @param {String} user the user's name or ID to be removed
     */
    GApi.prototype.removeMemberFromOrganization = function (org, user, callbacks, context) {
        callbacks = callbacks || {};

        this.request({
            method: 'DELETE',
            url: '/org/' + encodeURIComponent(org) + '/member/' + encodeURIComponent(user),
            auth: true
        }, {
            done: function () {
                if (this._user && this._user.id === user) {
                    this.loadUser();
                }
                if (callbacks.done) {
                    callbacks.done();
                }
            }.bind(this),
            fail: callbacks.fail,
            always: callbacks.always,
            progress: callbacks.progress
        }, context);
    };
})(this);