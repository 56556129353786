(function (_) {
    var hostname = location.hostname.toLowerCase();
    var ENVIRONMENT = 'external';

    if (hostname === 'www.gravit.io' || hostname === 'gravit.io') {
        ENVIRONMENT = 'production';
    } else if (hostname.indexOf('gravit.io') >= 0) {
        ENVIRONMENT = 'sandbox';
        console.log('WARN, running in sandbox mode.');
    } else if (hostname.indexOf('localhost') >= 0) {
        ENVIRONMENT = 'local';
        console.log('WARN, running in local mode.');
    }

    // Init tracking stuff when not running externally
    if (ENVIRONMENT !== 'external') {
        window.googleAnalyticsId = ENVIRONMENT === 'production' ? 'UA-62755463-2' : 'UA-62755463-3';
        window.intercomId  = ENVIRONMENT === 'production' ? 'q0dleodl' : 'ki1ub01h';

        // Google Analytics
        (function (i, s, o, g, r, a, m) {
            i['GoogleAnalyticsObject'] = r;
            i[r] = i[r] || function () {
                (i[r].q = i[r].q || []).push(arguments)
            }
                , i[r].l = 1 * new Date();
            a = s.createElement(o),
                m = s.getElementsByTagName(o)[0];
            a.async = 1;
            a.src = g;
            m.parentNode.insertBefore(a, m)
        })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

        // Intercom
        (function () {
            var w = window;
            var ic = w.Intercom;
            if (typeof ic === "function") {
                ic('reattach_activator');
                ic('update', intercomSettings);
            } else {
                var d = document;
                var i = function () {
                    i.c(arguments)
                };
                i.q = [];
                i.c = function (args) {
                    i.q.push(args)
                };
                w.Intercom = i;
                function l() {
                    var s = d.createElement('script');
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = 'https://widget.intercom.io/widget/' + w.intercomId;
                    var x = d.getElementsByTagName('script')[0];
                    x.parentNode.insertBefore(s, x);
                }

                if (w.attachEvent) {
                    w.attachEvent('onload', l);
                } else {
                    w.addEventListener('load', l, false);
                }
            }
        })();
    }

    /**
     * @class GApi
     * @param {String} [rootURL] optional root URL to the api, if null then
     * will automatically point to http://gravit.io/api. May not contain an
     * ending slash. Defaults to null.
     * @param {String} [sessionID] optional sessionID used for authorization
     * Gravit API Access Library
     * @param {{}} [settings]
     */
    function GApi(rootURL, sessionID, settings) {
        this._rootURL = rootURL ? rootURL : 'https://gravit.io/api';
        this._sessionID = sessionID;
        this._settings = settings || {};
        this._listeners = {};
    }

    /**
     * @enum
     */
    GApi.Event = {
        /**
         * Fired everytime the api makes any request
         */
        Request: 'request',

        /**
         * Fired when the current user has been updated
         */
        UserUpdated: 'user-updated'
    };

    /**
     * @type {number}
     */
    GApi.THUMBNAIL_SIZE = 250;

    /**
     * @type {String}
     * @private
     */
    GApi.prototype._sessionID = null;

    /**
     * @type {Array}
     * @private
     */
    GApi.prototype._listeners = null;

    /**
     * @type {{*}}
     * @private
     */
    GApi.prototype._user = null;

    /**
     * @type {String}
     * @private
     */
    GApi.prototype._activeFolder = null;

    /**
     * @type {String}
     * @private
     */
    GApi.prototype._activeAccount = null;

    /**
     * @type {String}
     * @private
     */
    GApi.prototype._rootURL = null;

    /**
     * @type {number}
     * @private
     */
    GApi.prototype._uploadCounter = 0;

    /**
     * Return the previously set active account's id or null
     * @return {String}
     */
    GApi.prototype.getActiveAccount = function () {
        return this._activeAccount;
    };

    /**
     * Set the active account's id or null
     * @param {String} activeAccount
     */
    GApi.prototype.setActiveAccount = function (activeAccount) {
        this._activeAccount = activeAccount;
    };

    /**
     * Return the previously set active folder's reference or null
     * @return {String}
     */
    GApi.prototype.getActiveFolder = function () {
        return this._activeFolder;
    };

    /**
     * Set the active folder's reference or null
     * @param {String} activeFolder
     */
    GApi.prototype.setActiveFolder = function (activeFolder) {
        this._activeFolder = activeFolder;
    };

    /**
     * Return current user. This will not be available until
     * loadUser() has been called and will only contain a valid value
     * when the current user is logged in.
     * @returns {*}
     */
    GApi.prototype.getUser = function () {
        return this._user;
    };

    /**
     * Checks and returns if the api is currently uploading something
     * @returns {boolean}
     */
    GApi.prototype.isUploading = function () {
        return this._uploadCounter > 0;
    };

    /**
     * Returns the current environment (production,sandbox,local or external)
     * @returns {String}
     */
    GApi.prototype.getEnvironment = function () {
        return ENVIRONMENT;
    };

    /**
     * Subscribe a listener to a given event
     * @param {GApi.Event} event
     * @param {Function} listener
     */
    GApi.prototype.subscribe = function (event, listener) {
        if (!this._listeners.hasOwnProperty(event)) {
            this._listeners[event] = [];
        }

        this._listeners[event].push(listener);
    };

    /**
     * Unsubscribe a listener from a given event
     * @param {GApi.Event} event
     * @param {Function} listener
     */
    GApi.prototype.unsubscribe = function (event, listener) {
        if (this._listeners.hasOwnProperty(event)) {
            var ls = this._listeners[event];
            var id = ls.indexOf(listener);
            if (id >= 0) {
                ls.splice(id, 1);
                if (ls.length === 0) {
                    delete this._listeners[event];
                }
            }
        }
    };

    /**
     * Trigger a global api event
     * @param {GApi.Event} event
     * @param {Array<*>} [args]
     */
    GApi.prototype.trigger = function (event, args) {
        if (this._listeners.hasOwnProperty(event)) {
            args = args || [];
            if (!(args instanceof Array)) {
                args = [args];
            }

            var ls = this._listeners[event].slice();
            ls.forEach(function (cb) {
                cb.apply(cb, args);
            });
        }
    };

    /**
     * Convert a query object into a query string
     * @param {*} query query object, can be empty or null
     * @param {Boolean} [addPrefix] if true and query is valid
     * then will prefix the query string with a ?. Defaults to false
     * @return {String} query string or empty string if query is empty or null
     */
    GApi.prototype.queryToString = function (query, addPrefix) {
        var queryString = '';

        if (query) {
            for (var k in query) {
                if (queryString) {
                    queryString += '&';
                }

                var v = query[k];
                if (v !== undefined && v !== null) {
                    if (v instanceof Array) {
                        for (var i = 0; i < v.length; ++i) {
                            if (i > 0) queryString += '&';
                            queryString += k + '=' + encodeURIComponent(v);
                        }
                    } else {
                        queryString += k + '=' + encodeURIComponent(v);
                    }
                } else {
                    queryString += k;
                }
            }
        }

        if (queryString && addPrefix) {
            queryString = '?' + queryString;
        }

        return queryString;
    };

    /**
     * Make a web request
     * @param options
     * @param callbacks
     * @param context
     */
    GApi.prototype.request = function (options, callbacks, context) {
        options = options || {};
        callbacks = callbacks || {};

        var query = options.query || {};
        var headers = options.headers;
        var method = options.method || 'GET';
        var auth = options.auth || false;
        var url = options.url;
        var data = options.data || null;
        var dataType = options.dataType || null;
        var responseType = options.responseType || null;
        var contentSize = options.contentSize;

        if (!url) {
            throw new Error('Invalid or no url given.');
        }

        if (url.charAt(0) === '/' && this._rootURL && url.indexOf('/test/') !== 0) {
            url = this._rootURL + url;
        }

        var xhr = new XMLHttpRequest();

        if (auth) {
            if (this._sessionID) {
                query.sessionID = this._sessionID;
            } else {
                xhr.withCredentials = true;
            }
        }

        var queryString = this.queryToString(query, false);
        if (queryString) {
            if (url.indexOf('?') >= 0) {
                url += '&';
            } else {
                url += '?';
            }
            url += queryString;
        }

        if (responseType) {
            switch (responseType) {
                case 'blob':
                case 'arraybuffer':
                    xhr.responseType = responseType;
                    break;
                default:
                    break;
            }
        }

        xhr.open(method, url);

        if (dataType) {
            switch (dataType) {
                case 'json':
                    xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
                    if (data && typeof data !== 'string') {
                        data = JSON.stringify(data);
                    }
                    break;

                default:
                    xhr.setRequestHeader('Content-Type', dataType);
            }
        }

        if (context) {
            if (!context.requests) {
                context.requests = [];
            }

            if (!context.abort) {
                context.abort = function () {
                    this.requests.map(function (req) {
                        req.abort();
                    });
                    this.requests = [];
                };
            }
        }

        var always = function () {
            if (context && context.requests && context.requests instanceof Array) {
                var index = context.requests.indexOf(xhr);
                if (index >= 0) {
                    context.requests.splice(index, 1);
                }
            }

            if (callbacks.always) {
                callbacks.always();
            }

            // Trigger global request event
            this.trigger(GApi.Event.Request);
        }.bind(this);

        var fail = function (status, statusText, response) {
            if (callbacks.fail) {
                callbacks.fail(status, statusText, response);
            }
            always()
        }.bind(this);

        var done = function (response, status, xhr) {
            if (callbacks.done) {
                callbacks.done(response, status, xhr);
            }
            always();
        }.bind(this);

        var progress = function (evt) {
            var totalSize = evt.total || contentSize;

            if (!isNaN(evt.loaded) && !isNaN(totalSize) && callbacks.progress) {
                var max = totalSize;
                var current = evt.loaded;

                var percentage = Math.round((current * 100) / max);

                callbacks.progress(percentage, current, max);
            }
        };

        function extractResponse(forceResponseType) {
            var response = this.response;

            if (response) {
                var contentType = this.getResponseHeader('Content-Type') || '';

                if (contentType.indexOf('application/json') === 0 || ('json' === responseType && forceResponseType)) {
                    try {
                        response = JSON.parse(response);
                    } catch (e) {
                        // ignore
                    }
                }
            }

            return response;
        }

        xhr.onload = function () {
            var response = extractResponse.call(this, true);

            if (this.status >= 200 && this.status < 300) {
                done(response, this.status, this);
            } else {
                fail(this.status, this.statusText, response);
            }
        };

        xhr.onerror = function () {
            fail(this.status, this.statusText, extractResponse.call(this, false));
        };

        xhr.addEventListener('progress', progress, false);
        xhr.upload.addEventListener('progress', progress, false);

        if (headers) {
            Object.keys(headers).forEach(function (key) {
                xhr.setRequestHeader(key, headers[key]);
            });
        }

        xhr.send(data);
    };

    _.GApi = GApi;
})(this);