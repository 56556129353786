(function (_) {
    /**
     * Load / refresh current user if any
     */
    GApi.prototype.loadUser = function (callbacks, context) {
        var callbacks = callbacks || {};

        return this.request({
            method: 'GET',
            url: '/user/current',
            auth: true
        }, {
            done: function (user) {
                this._user = user;
                this.trigger(GApi.Event.UserUpdated);

                if (callbacks.done) {
                    callbacks.done(user);
                }
            }.bind(this),
            fail: function (status, statusText) {
                var oldUser = this._user;
                this._user = null;

                if (oldUser !== null) {
                    this.trigger(GApi.Event.UserUpdated);
                }

                if (callbacks.fail) {
                    callbacks.fail(status, statusText);
                }
            }.bind(this),
            always: function () {
                this._initTracking();

                if (callbacks.always) {
                    callbacks.always();
                }
            }.bind(this)
        }, context);
    };

    /**
     * Update the current user's profile
     * @param {*} data the profile data
     */
    GApi.prototype.updateUserProfile = function (data, callbacks, context) {
        callbacks = callbacks || {};

        this.request({
            method: 'PUT',
            url: '/user/profile',
            auth: true,
            dataType: 'json',
            data: data
        }, {
            done: function () {
                this._refreshUser();
                if (callbacks.done) {
                    callbacks.done();
                }
            }.bind(this),
            fail: callbacks.fail,
            progress: callbacks.progress,
            always: callbacks.always
        }, context);
    };

    /**
     * Update the current user's avatar
     * @param {Blob} avatarBlob the avatar's binary blob (png image)
     */
    GApi.prototype.updateUserAvatar = function (avatarBlob, callbacks, context) {
        var updateFunc = function (avatar, callbacks, context) {
            this.updateUserProfile({avatar: avatar}, callbacks, context);
        }.bind(this);

        this._updateAccountAvatar('/user/avatar', avatarBlob, updateFunc, callbacks, context);
    };

    /**
     * Update the current user's password
     * @param {*} [context] context object
     * @returns {Promise}
     */
    GApi.prototype.updateUserPassword = function (oldPassword, newPassword, confirmPassword, callbacks, context) {
        this.request({
            method: 'PUT',
            url: '/user/password',
            auth: true,
            dataType: 'json',
            data: {
                oldPassword: oldPassword,
                newPassword: newPassword,
                confirmPassword: confirmPassword
            }
        }, callback, context);
    };
})(this);
