(function (_) {
    var TRACKING_INITIALIZED = false;

    /**
     * Track a page change
     * @param {String} path the page path
     * @param {String} title the page's title
     */
    GApi.prototype.trackPageEvent = function (path, title) {
        if (TRACKING_INITIALIZED) {
            if (this.getEnvironment() !== 'production') {
                console.log('Track page event', path, title);
            }

            ga('set', {
                page: path,
                title: title,
            });
            ga('send', 'pageview');

            window.Intercom('update');
        }
    };

    /**
     * Tracl a global metric event
     * @param {String} category Typically the object that was interacted with (e.g. 'Video')
     * @param {String} action The type of interaction (e.g. 'play')
     * @param {Number} [value] A numeric value associated with the event (e.g. 42)
     * @param {String} [label] Useful for categorizing events (e.g. 'Fall Campaign')
     */
    GApi.prototype.trackGlobalEvent = function (category, action, value, label) {
        if (TRACKING_INITIALIZED) {
            if (this.getEnvironment() !== 'production') {
                console.log('Track global event', category, action, label, value);
            }

            ga('send', 'event', category, action, label, value);
        }
    };

    /**
     * Track an event bound/specific to the currently logged in user.
     * This is ignored if the current user is not logged in.
     * @param {String} event The name of the event that occurred. - a good event name is typically
     * a past tense ‘verb-noun’ combination, to improve readability, for example updated-plan.
     * @param {*} [metadata] optional metadata object about the event.
     */
    GApi.prototype.trackUserEvent = function (event, metadata) {
        if (this._user && TRACKING_INITIALIZED) {
            if (this.getEnvironment() !== 'production') {
                console.log('Track user event', event, metadata);
            }

            Intercom('trackEvent', event, metadata);
        }
    };

    GApi.prototype._initTracking = function () {
        if (!TRACKING_INITIALIZED && this.getEnvironment() !== 'external') {
            var user = this._user;

            if (this.getEnvironment() !== 'production') {
                console.log('Init tracking for user/lead', user);
            }

            // Init Google Analytics
            ga('create', window.googleAnalyticsId, {
                userId: user ? user.id : undefined
            });
            ga('send', 'pageview');

            // Init intercom
            if (user) {
                window.Intercom('boot', {
                    app_id: window.intercomId,
                    name: user.name,
                    fullName: user.profile.name,
                    email: user.email,
                    user_id: user.id,
                    created_at: new Date(user.created).getTime() / 1000
                });
            } else {
                window.Intercom('boot', {
                    app_id: window.intercomId
                });
            }

            window.Intercom('update');

            TRACKING_INITIALIZED = true;
        }
    };
})(this);