(function (_) {
    /**
     * Get a list of accounts
     * @param (*} query
     */
    GApi.prototype.listAccounts = function (query, callbacks, context) {
        return this.request({
            url: '/account/list',
            query: query,
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Get information about an account
     * @param {String} account the account's name or ID
     */
    GApi.prototype.getAccount = function (account, callbacks, context) {
        return this.request({
            url: '/account/' + encodeURIComponent(account),
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Get list of notifications for a given account
     * @param {String} account name or id of account to get notifications for
     * @param (*} query
     */
    GApi.prototype.listAccountNotifications = function (account, query, callbacks, context) {
        callbacks = callbacks || {};

        this.request({
            url: '/account/' + encodeURIComponent(account) + '/notification/list',
            query: query,
            auth: true,
            responseType: 'json'
        }, callbacks, context);
    };

    /**
     * Validate an account's name
     * @param {String} name the name to validate for
     */
    GApi.prototype.validateAccountName = function (name, callbacks, context) {
        return this.request({
            url: '/account/validate_name',
            query: {
                name: name
            }
        }, callbacks, context);
    };

    GApi.prototype._updateAccountAvatar = function (url, avatarBlob, profileUpdateFunc, callbacks, context) {
        callbacks = callbacks || {};

        this
            .request({
                method: 'PUT',
                url: url,
                auth: true,
                responseType: 'json'
            }, {
                done: function (data) {
                    this._uploadCounter++;

                    this
                        .request({
                            method: 'PUT',
                            url: data.uploadUrl,
                            dataType: 'image/png',
                            data: avatarBlob
                        }, {
                            done: function () {
                                profileUpdateFunc(data.avatarUrl, {
                                    done: callbacks.done,
                                    fail: callbacks.fail,
                                    progress: callbacks.progress,
                                    always: function () {
                                        this._uploadCounter--;
                                        if (callbacks.always) {
                                            callbacks.always();
                                        }
                                    }.bind(this)
                                }, context);
                            },
                            fail: callbacks.fail,
                            progress: callbacks.progress
                        }, context);
                }.bind(this),
                fail: callbacks.fail
            }, context);
    };
})(this);
