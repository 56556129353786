(function (_) {
    /**
     * Converts a given dataUrl into a binary blob
     * @param {String} dataUrl the data url to convert
     * @returns {Blob}
     */
    GApi.prototype.dataUrlToBlob = function (dataUrl) {
        var BASE64_MARKER = ';base64,';
        if (dataUrl.indexOf(BASE64_MARKER) == -1) {
            var parts = dataUrl.split(',');
            var contentType = parts[0].split(':')[1];
            var raw = parts[1];

            return new Blob([raw], {type: contentType});
        }

        var parts = dataUrl.split(BASE64_MARKER);
        var contentType = parts[0].split(':')[1];
        var raw = window.atob(parts[1]);
        var rawLength = raw.length;

        var uInt8Array = new Uint8Array(rawLength);

        for (var i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }

        return new Blob([uInt8Array], {type: contentType});
    };

    /**
     * Creates a thumbnail png from a given image and a given size
     * @param image the image to create a thumbnail from, maybe either an image
     * or a file/blob
     * @param size the size of the thumbnail
     * @param done callback called with a data url containing the thumbnail png data-url,
     * the thumbnail width and height and the source image
     */
    GApi.prototype.imageThumbnail = function (image, size, done) {
        function thumbnail() {
            var width = image.naturalWidth;
            var height = image.naturalHeight;

            var scale = 1.0;

            if (width > height) {
                scale = size / width;
            } else {
                scale = size / height;
            }

            // no upscaling
            scale = scale > 1.0 ? 1.0 : scale;
            width *= scale;
            height *= scale;

            var tmp = new Image(), canvas, context, cW, cH;

            var currentWidth = image.naturalWidth;
            var currentHeight = image.naturalHeight;

            tmp.src = image.src;
            tmp.onload = function () {
                var canvas = document.createElement('canvas');

                currentWidth /= 2;
                currentHeight /= 2;

                if (currentWidth < width) currentWidth = width;
                if (currentHeight < height) currentHeight = height;

                canvas.width = currentWidth;
                canvas.height = currentHeight;

                var context = canvas.getContext('2d');
                context.drawImage(tmp, 0, 0, currentWidth, currentHeight);

                var data = canvas.toDataURL('image/png');

                if (currentWidth <= width || currentHeight <= height) {
                    done(data, currentWidth, currentHeight, image);
                    return;
                }

                tmp.src = data;
            }
        }

        if (image instanceof File || image instanceof Blob || image instanceof ArrayBuffer) {
            if (image instanceof ArrayBuffer) {
                image = new Blob([image], {});
            }

            var reader = new FileReader();
            reader.onload = function (readerEvent) {
                image = new Image();
                image.onload = function () {
                    thumbnail();
                };
                image.src = readerEvent.target.result;
            };
            reader.readAsDataURL(image);
        } else {
            thumbnail(image);
        }
    }
})(this);
